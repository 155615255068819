<template>
  <v-app>
    <v-dialog
      v-model="dialogLoading"
      hide-overlay
      v-if="loading"
      persistent
      width="300"
    >
      <v-card color="primary" dark>
        <v-card-text>
          <h2 class="white--text">Loading</h2>
          <v-progress-linear
            v-if="loading"
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-system-bar class="d-print-none pt-3" height="30" app lights-out>
      <v-toolbar-items class="text-right">
        <v-btn
          large
          rounded
          dark
          href="javascript:window.print()"
          class="title mr-2 rounded"
          color="info"
        >
          <v-icon class="mr-1" color="white" large>mdi-printer</v-icon>พิมพ์
        </v-btn>
      </v-toolbar-items>
    </v-system-bar>

    <div id="exportContent" style="mso-page-orientation: landscape;">
      <div class="page">
        <div v-for="(item, index) in transference_personnels" :key="index">
          <div class="text_l bold16 text-center">
            แบบบันทึกคะแนน องค์ประกอบ ตัวชี้วัดและคะแนนในการตรวจสอบ <br />
            ตามองค์ประกอบการประเมินศักยภาพของผู้ประสงค์ขอย้าย
            <br />
            ตำแหน่งครู (การย้ายกรณีปกติ) องค์ประกอบที่ 1 - 6
            <br />
            ย้ายประจำปี
            {{ periods.period_vecprovince_year }} ครั้งที่
            {{ periods.period_vecprovince_times }}
          </div>

          <table width="100%" class="text_j pt-2">
            <tr>
              <td width="15%" class="bold16" align="left">
                ชื่อ-นามสกุล ผู้ขอย้าย
              </td>
              <td width="20%" class="td_line" align="center">
                {{ item.title_s + item.frist_name + " " + item.last_name }}
              </td>
              <td  class="bold16" align="center">เลขที่ตำแหน่ง</td>
              <td width="15%" class="td_line text-center">{{ item.id_position }}</td>
              <td  class="bold16" align="center">สังกัด</td>
              <td width="" class="td_line text-center">
                {{ item.college_name }}
              </td>
            </tr>          
          </table>

          <table width="100%" class="table text_j mt-2">
            <tr>
              <td width="5%" class="bold16 table" align="center">ที่</td>
              <td width="45%" class="bold16 table" align="center">
                ตัวชี้วัด / องค์ประกอบ
              </td>
              <td width="10%" class="bold16 table" align="center">
                เกณฑ์การให้คะแนน
              </td>
              <td width="10%" class="bold16 table" align="center">
                คะแนนที่ได้รับ
              </td>
              <td width="30%" class="bold16 table" align="center">
                กรอบการพิจารณา
              </td>
            </tr>

            <tr>
              <td rowspan="5" class="text_al text-center table bold16">1</td>
              <td class="table pa-1 text_al bgcolor bold16">
                เหตุผลในการขอย้าย
              </td>
              <td
                class="pa-1 table text_al text-center bgcolor bold16"
                colspan="2"
              >
                (30 คะแนน)
              </td>
              <td rowspan="5" class="pa-1 table text_al">
                <u class="font-weight-bold">พิจารณาจาก</u> <br />
                1. สำเนาทะเบียนบ้านปัจจุบันของผู้ขอย้าย <br />
                2. สำเนาทะเบียนบ้านปัจจุบันของคู่สมรส/บิดา มารดา <br />
                3. สำเนาใบสำคัญการสมรส<br />
                4. สำเนาสูติบัตร<br />
                5. เอกสารหลักฐานอื่นๆ<br />
                <div class="text_pal">
                  ทั้งนี้
                  ต้องมีชื่ออยู่ในทะเบียนบ้านปัจจุบันติดต่อกันไม่น้อยกว่า 12
                  เดือน นับถึงวันสุดท้ายที่กำหนดให้ยื่นคำร้องขอย้าย
                  ภูมิลำเนาหมายถึง จังหวัด อำเภอ ตำบล
                  เดียวกันกับสถานศึกษาที่ประสงค์ขอย้าย
                </div>
              </td>
            </tr>
            <tr>
              <td class="table pa-1">
                1) ดูแลบิดา มารดา และกลับภูมิลำเนา
                และอยู่รวมกับคู่สมรสและหรือบุตร
              </td>
              <td class="table pa-1 text-center">30 คะแนน</td>
              <td rowspan="4"></td>
            </tr>
            <tr>
              <td class="table pa-1">
                2) ดูแลบิดา มารดา และกลับภูมิลำเนา หรือดูแลบิดา มารดา
                และอยู่รวมกับคู่สมรสและหรือบุตร
              </td>
              <td class="table pa-1 text-center">25 คะแนน</td>
            </tr>
            <tr>
              <td class="table pa-1">
                3) ดูแลบิดา มารดา หรือกลับภูมิลำเนา
                หรืออยู่รวมกับคู่สมรสและหรือบุตร อย่างใดอย่างหนึ่ง
              </td>
              <td class="table pa-1 text-center">20 คะแนน</td>
            </tr>
            <tr>
              <td class="table pa-1">4) เหตุผลอื่น ๆ</td>
              <td class="table pa-1 text-center">15 คะแนน</td>
            </tr>

            <tr>
              <td rowspan="6" class="text_al text-center table bold16">2</td>
              <td class="pa-1 table text_al bgcolor bold16">
                ผลการปฏิบัติงานที่เกิดกับผู้เรียนและสถานศึกษาปัจจุบัน
              </td>
              <td
                colspan="2"
                class="pa-1 table text_al bgcolor bold16 text-center"
              >
                (10 คะแนน)
              </td>
              <td rowspan="6" class="pa-1 table text_al">
                <u class="font-weight-bold">พิจารณาจาก</u> <br />
                <div class="text_pal">
                  สำเนาเอกสารหลักฐาน โล่รางวัล/ประกาศนียบัตร
                  ซึ่งแสดงให้เห็นถึงผลงานหรือรางวัลที่เกิดจากการปฏิบัติงานในหน้าที่ที่เกิดขึ้นกับผู้เรียนและสถานศึกษาในระดับสูงสุด
                  1 ผลงาน/รางวัล ย้อนหลังไม่เกิน 3 ปี
                  นับถึงวันสุดท้ายของการยื่นคำร้องขอย้ายประจำปี
                </div>
                <div class="text_pal">
                  ทั้งนี้
                  ให้ผู้ประสงค์ขอย้ายและผู้บังคับบัญชาหรือผู้รักษาการในตำแหน่งที่สำนักงานคณะกรรมการการอาชีวศึกษาแต่งตั้งตามลำดับลงลายมือชื่อรับรองสำเนาถูกต้องของเอกสารทุกฉบับ
                  และไม่ต้องแนบรูปภาพประกอบ
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="3" class="table pa-1 text_al bgcolor">
                ผลงานหรือรางวัลที่เกิดจากการปฏิบัติงานในหน้าที่ที่เกิดขึ้นกับผู้เรียนและสถานศึกษาในระดับสูงสุด
                จำนวน 1 ผลงาน/รางวัล
              </td>
            </tr>
            <tr>
              <td class="table pa-1 text_al">
                1) ระดับชาติหรือที่สูงกว่าขึ้นไป
                (ที่สำนักงานคณะกรรมการการอาชีวศึกษาเป็นผู้จัด) หน่วยงานภาครัฐ
                หรือภาคเอกชนจัดการแข่งขันในระดับประเทศขึ้นไป
              </td>
              <td class="table pa-1 text-center">10 คะแนน</td>
              <td rowspan="4"></td>
            </tr>
            <tr>
              <td class="table pa-1 text_al">
                2) ระดับภาค/สถาบันการอาชีวศึกษา/ระดับสถาบัน /หน่วยงานภาครัฐ
                หรือภาคเอกชนจัดการแข่งขันในระดับภาค
              </td>
              <td class="table pa-1 text-center">8 คะแนน</td>
            </tr>
            <tr>
              <td class="table pa-1 text_al">
                3) ระดับจังหวัด /หน่วยงานภาครัฐ
                หรือภาคเอกชนจัดการแข่งขันในระดับจังหวัด
              </td>
              <td class="table pa-1 text-center">6 คะแนน</td>
            </tr>
            <tr>
              <td class="table pa-1 text_al">
                4) ระดับท้องถิ่น/ระดับสถานศึกาา/หน่วยงานภาครัฐ
                หรือภาคเอกชนจัดการแข่งขันในระดับท้องถิ่นหรือสถานศึกษา
              </td>
              <td class="table pa-1 text-center">4 คะแนน</td>
            </tr>
          </table>

          <!-- New Page -->
          <p style="page-break-before: always;">&nbsp;</p>
          <!-- 2 -->

          <div class="text_l bold16 text-center">
            แบบบันทึกคะแนน องค์ประกอบ ตัวชี้วัดและคะแนนในการตรวจสอบ <br />
            ตามองค์ประกอบการประเมินศักยภาพของผู้ประสงค์ขอย้าย
            <br />
            ตำแหน่งครู (การย้ายกรณีปกติ) องค์ประกอบที่ 1 - 6
            <br />
            ย้ายประจำปี
            {{ periods.period_vecprovince_year }} ครั้งที่
            {{ periods.period_vecprovince_times }}
          </div>

          <table width="100%" class="text_j pt-2">
            <tr>
              <td width="15%" class="bold16" align="left">
                ชื่อ-นามสกุล ผู้ขอย้าย
              </td>
              <td width="20%" class="td_line" align="center">
                {{ item.title_s + item.frist_name + " " + item.last_name }}
              </td>
              <td  class="bold16" align="center">เลขที่ตำแหน่ง</td>
              <td width="15%" class="td_line text-center">{{ item.id_position }}</td>
              <td  class="bold16" align="center">สังกัด</td>
              <td width="" class="td_line text-center">
                {{ item.college_name }}
              </td>
            </tr>          
          </table>

          <table width="100%" class="text_j mt-2">
            <tr>
              <td width="5%" class="bold16 table" align="center">ที่</td>
              <td width="45%" class="bold16 table" align="center">
                ตัวชี้วัด / องค์ประกอบ
              </td>
              <td width="10%" class="bold16 table" align="center">
                เกณฑ์การให้คะแนน
              </td>
              <td width="10%" class="bold16 table" align="center">
                คะแนนที่ได้รับ
              </td>
              <td width="30%" class="bold16 table" align="center">
                กรอบการพิจารณา
              </td>
            </tr>
            <tr>
              <td rowspan="3" class="text_al text-center table bold16">3</td>
              <td class="pa-1 table text_al bgcolor bold16">
                สภาพความยากลำบากในการปฏิบัติงานในสถานศึกษาปัจจุบัน
              </td>
              <td
                colspan="2"
                class="pa-1 table text_al bgcolor bold16 text-center"
              >
                (5 คะแนน)
              </td>
              <td rowspan="3" class="pa-1 table text_al">
                <u class="font-weight-bold">พิจารณาจาก</u> <br />
                <div class="text_pal">
                  รายชื่อสถานศึกษาตามประกาศพื้นที่พิเศษของกระทรวงการคลังที่ประกาศใช้อยู่ในวันสุดท้ายที่ยื่นคำร้องขอย้ายประจำปี
                  (สามารถติดตามและดาวน์โหลดได้จากเว็บไซต์ (www.cgd.go.th)
                </div>
                <div class="text_pal">
                  หมายเหตุ
                  ให้ถือว่าทั้งผู้ที่ขอย้ายเข้าหรือย้ายออกจากสถานศึกษาที่ตั้งอยู่ในเขตพัฒนาพิเศษเฉพาะกิจจังหวัดชายแดนภาคใต้
                  เฉพาะจังหวัดยะลา นราธิวาส ปัตตานี และ 4 อำเภอในจังหวัดสงขลา
                  (อ.จะนะ อ.เทพา อ.นาทวี อ.สะบ้าย้อย)
                  มีสภาพเป็นผู้ที่มีความยากลำบากในการปฏิบัติงานในสถานศึกษาปัจจุบัน
                </div>
              </td>
            </tr>
            <tr>
              <td class="table pa-1 text_al">
                1)
                สถานศึกษาที่ตั้งอยู่ในเขตพัฒนาพิเศษเฉพาะกิจจังหวัดชายแดนภาคใต้
                เฉพาะจังหวัดยะลา นราธิวาส ปัตตานี และ 4 อำเภอ ในจังหวัดสงขลา
                (อ.จะนะ อ.เทพา อ.นาทวี อ.สะบ้าย้อย)
                /ตั้งอยู่ในพื้นที่พิเศษตามประกาศกระทรวงการคลัง
              </td>
              <td class="table pa-1 text-center">5 คะแนน</td>
              <td rowspan="2"></td>
            </tr>
            <tr>
              <td class="table pa-1 text_al">
                2) สถานศึกษาตั้งอยู่ในพื้นที่ปกติ
              </td>
              <td class="table pa-1 text-center">1 คะแนน</td>
            </tr>

            <tr>
              <td rowspan="5" class="text_al text-center table bold16">4</td>
              <td class="pa-1 table text_al bgcolor bold16">
                ระยะเวลาการปฏิบัติหน้าที่สอนในสถานศึกษาปัจจุบัน
              </td>
              <td
                colspan="2"
                class="pa-1 table text_al bgcolor bold16 text-center"
              >
                (5 คะแนน)
              </td>

              <td rowspan="5" class="pa-1 table text_al">
                <u class="font-weight-bold">พิจารณาจาก</u> <br />
                <div class="text_pal">
                  ระยะเวลาการปฏิบัติหน้าที่สอนในสถานศึกษาปัจจุบันนับถึงวันสุดท้าย
                  ที่กำหนดให้ยื่นคำร้องขอย้าย โดยพิจารณาจากสำเนา ก.พ. 7 หรือ
                  ก.ค.ศ. 16 ที่เป็นปัจจุบัน
                  โดยมีเจ้าหน้าที่งานทะเบียนประวัติลงลายมือชื่อรับรองสำเนาถูกต้อง
                </div>
                <div class="text_pal">
                  ทั้งนี้ ระยะเวลาเกิน 15 วัน ให้นับเป็น 1
                  เดือน(หลักเกณฑ์กำหนดให้จัดการเรียนการสอนในสถานศึกษาปัจจุบัน
                  ติดต่อกันมาแล้วไม่น้อยกว่า 24 เดือน
                  นับถึงวันสุดท้ายที่กำหนดให้ยื่นคำร้องขอย้าย)
                </div>
              </td>
            </tr>
            <tr>
              <td class="table pa-1 text_al">60 เดือนขึ้นไป</td>
              <td class="table pa-1 text-center">5 คะแนน</td>
              <td rowspan="4" class="table"></td>
            </tr>
            <tr>
              <td class="table pa-1 text_al">48 – 59 เดือน</td>
              <td class="table pa-1 text-center">4 คะแนน</td>
            </tr>
            <tr>
              <td class="table pa-1 text_al">36 – 47 เดือน</td>
              <td class="table pa-1 text-center">3 คะแนน</td>
            </tr>
            <tr>
              <td class="table pa-1 text_al">24 – 35 เดือน</td>
              <td class="table pa-1 text-center">2 คะแนน</td>
            </tr>
          </table>

          <!-- New Page -->
          <p style="page-break-before: always;">&nbsp;</p>
          <!-- 2 -->

          <div class="text_l bold16 text-center">
            แบบบันทึกคะแนน องค์ประกอบ ตัวชี้วัดและคะแนนในการตรวจสอบ <br />
            ตามองค์ประกอบการประเมินศักยภาพของผู้ประสงค์ขอย้าย
            <br />
            ตำแหน่งครู (การย้ายกรณีปกติ) องค์ประกอบที่ 1 - 6
            <br />
            ย้ายประจำปี
            {{ periods.period_vecprovince_year }} ครั้งที่
            {{ periods.period_vecprovince_times }}
          </div>

          <table width="100%" class="text_j pt-2">
            <tr>
              <td width="15%" class="bold16" align="left">
                ชื่อ-นามสกุล ผู้ขอย้าย
              </td>
              <td width="20%" class="td_line" align="center">
                {{ item.title_s + item.frist_name + " " + item.last_name }}
              </td>
              <td  class="bold16" align="center">เลขที่ตำแหน่ง</td>
              <td width="15%" class="td_line text-center">{{ item.id_position }}</td>
              <td  class="bold16" align="center">สังกัด</td>
              <td width="" class="td_line text-center">
                {{ item.college_name }}
              </td>
            </tr>          
          </table>

          <table width="100%" class="table text_j mt-2">
            <tr>
              <td width="5%" class="bold16 table" align="center">ที่</td>
              <td width="45%" class="bold16 table" align="center">
                ตัวชี้วัด / องค์ประกอบ
              </td>
              <td width="10%" class="bold16 table" align="center">
                เกณฑ์การให้คะแนน
              </td>
              <td width="10%" class="bold16 table" align="center">
                คะแนนที่ได้รับ
              </td>
              <td width="30%" class="bold16 table" align="center">
                กรอบการพิจารณา
              </td>
            </tr>
            <tr>
              <td rowspan="4" class="text_al text-center table bold16">5</td>
              <td class="pa-1 table text_al bgcolor bold16">
                การรักษาวินัย คุณธรรม จริยธรรม และจรรยาบรรณวิชาชีพ
              </td>
              <td
                colspan="2"
                class="pa-1 table text_al bgcolor bold16 text-center"
              >
                (5 คะแนน)
              </td>
              <td rowspan="4" class="pa-1 table text_al">
                <u class="font-weight-bold">พิจารณาจาก</u> <br />
                <div class="text_pal">
                  สำเนาเกียรติบัตร หรือสำเนาเอกสารหลักฐานด้านวินัย
                  และจริยธรรมในระดับจังหวัดขึ้นไป ย้อนหลัง 3 ปี
                  นับถึงวันสุดท้ายที่กำหนดให้ยื่นคำร้องขอย้าย
                  โดยไม่ต้องแนบรูปภาพประกอบสำเนา ก.พ.7 หรือ ก.ค.ศ. 16
                  ที่เป็นปัจจุบัน
                  โดยมีเจ้าหน้าที่งานทะเบียนประวัติลงลายมือชื่อรับรองความถูกต้อง
                </div>
                <div class="text_pal">
                  ทั้งนี้ ผู้ใดได้รับการล้างมลทิน ตาม พ.ร.บ.ล้างมลทิน
                  ให้ถือว่าเป็นผู้เคยถูกลงโทษทางวินัยในระดับโทษนั้นๆ
                </div>
              </td>
            </tr>
            <tr>
              <td class="table pa-1 text_al">
                ได้รับการยกย่อง เชิดชูเกียรติ ด้านวินัย และจริยธรรม
                และไม่เคยถูกลงโทษทางวินัย หรือทางจรรยาบรรณวิชาชีพ
              </td>
              <td class="table pa-1 text-center">5 คะแนน</td>
              <td rowspan="3"></td>
            </tr>
            <tr>
              <td class="table pa-1 text_al">
                ไม่เคยถูกลงโทษทางวินัย หรือทางจรรยาบรรณวิชาชีพ
              </td>
              <td class="table pa-1 text-center">4 คะแนน</td>
            </tr>
            <tr>
              <td class="table pa-1 text_al">
                เคยถูกลงโทษทางวินัย หรือทางจรรยาบรรณวิชาชีพ
              </td>
              <td class="table pa-1 text-center">0 คะแนน</td>
            </tr>
            <tr>
              <td rowspan="6" class="text_al text-center table bold16">6</td>
              <td class="table pa-1 text_al bgcolor bold16">
                อายุราชการ
              </td>
              <td
                class="pa-1 table text_al text-center bgcolor bold16"
                colspan="2"
              >
                (5 คะแนน)
              </td>
              <td rowspan="6" class="pa-1 table text_al">
                <u class="font-weight-bold">พิจารณาจาก</u> <br />
                <div class="text_pal">
                  ระยะเวลาการดำรงตำแหน่งตั้งแต่วันที่บรรจุแต่งตั้งเข้ารับราชการ
                  ถึงวันสุดท้ายที่กำหนดให้ยื่นคำร้องขอย้าย โดยพิจารณาจากสำเนา
                  ก.พ. 7 หรือ ก.ค.ศ. 16 ที่เป็นปัจจุบัน
                  โดยมีเจ้าหน้าที่งานทะเบียนประวัติลงลายมือชื่อรับรองสำเนาถูกต้อง
                </div>
                <div class="text_pal">
                  ทั้งนี้ หากมีอายุราชการมีเศษของปี ตั้งแต่ 6 เดือนขึ้นไปนับเป็น
                  1 ปี
                </div>
              </td>
            </tr>
            <tr>
              <td class="table pa-1">ตั้งแต่ 10 ปีขึ้นไป</td>
              <td class="table pa-1 text-center">5 คะแนน</td>
              <td rowspan="5"></td>
            </tr>
            <tr>
              <td class="table pa-1">ระหว่าง 8-9 ปี</td>
              <td class="table pa-1 text-center">4 คะแนน</td>
            </tr>
            <tr>
              <td class="table pa-1">ระหว่าง 6-7 ปี</td>
              <td class="table pa-1 text-center">3 คะแนน</td>
            </tr>
            <tr>
              <td class="table pa-1">ระหว่าง 4-5 ปี</td>
              <td class="table pa-1 text-center">2 คะแนน</td>
            </tr>
            <tr>
              <td class="table pa-1">ต่ำกว่า 4 ปี</td>
              <td class="table pa-1 text-center">1 คะแนน</td>
            </tr>
            <tr>
              <td
                height="40px"
                colspan="3"
                class="table pa-1 text-center bgcolor bold16"
              >
                รวมคะแนนทั้งหมด
              </td>
              <td class="table pa-1 text-center bold16"></td>
              <td class="table pa-1 text-center bgcolor bold16"></td>
            </tr>
          </table>

          
          <table class="text_j" width="100%" align="center">
            <tr>
              <td width="70%"></td>
              <td class="bold16 text-center">
                ลงลายมือชื่อ กรรมการประเมิน
              </td>
            </tr>
            </table>
            <br>
            <table class="text_j" width="100%" align="center">
            <tr>
              <td width="70%"></td>
              <td class="text-center">
                ลงชื่อ................................................................
              </td>
            </tr>
            <tr>
              <td></td>

              <td class="text-center">
                (..................................................................)
              </td>
            </tr>
            <tr>
              <td></td>

              <td class="text-center">
                ตำแหน่ง............................................................. 
              </td>
            </tr>

            <tr>
              <td></td>

              <td class="text-center">
                วันที่........./.............................../...................
              </td>
            </tr>
          </table>

   
         
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    ApiKey: "HRvec2021",
    loading: true,
    dialogLoading: false,
    periods: [],
    transference_personnels: [],
    commiteevecpros: []
  }),

  async mounted() {
    await this.periodQuery();
    await this.commiteevecprosQueryAll();
    await this.transference_personnelQueryAll();
  },

  methods: {
    async periodQuery() {
      let result_period;
      result_period = await this.$http.post("period_vecprovince.php", {
        ApiKey: this.ApiKey,
        period_vecprovince_enable: "1",
        period_vecprovince_type: "discuss_teacher"
      });
      this.periods = result_period.data;
      if (this.periods.period_vecprovince_enable != "1")
        this.$router.push("/vecprovince");
    },

    async commiteevecprosQueryAll() {
      let result = await this.$http.post("commiteevecpro.php", {
        ApiKey: this.ApiKey,
        commiteevecproviceID: this.url_result,
        commiteeVecProYear: this.periods.period_vecprovince_year,
        commiteeVecProTime: this.periods.period_vecprovince_times
      });
      this.commiteevecpros = result.data;
    },

    async transference_personnelQueryAll() {
      let result = await this.$http.post("transference_personnel.php", {
        ApiKey: this.ApiKey,
        province_ID: this.url_result,
        time_s: this.periods.period_vecprovince_times,
        year_s: this.periods.period_vecprovince_year,
        type_move: "nm"
      });
      this.transference_personnels = result.data;
      
  
    },

    Export2Doc(element, filename) {
      var preHtml =
        "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-16'><title>Export HTML To Doc</title></head><body>";
      var postHtml = "</body></html>";
      var html =
        preHtml + document.getElementById(element).innerHTML + postHtml;

      var blob = new Blob(["\ufeff", html], {
        type: "application/msword"
      });

      // Specify link url
      var url =
        "data:application/vnd.ms-word;charset=utf-16," +
        encodeURIComponent(html);

      // Specify file name
      filename = filename ? filename + ".doc" : "document.doc";

      // Create download link element
      var downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = url;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }

      document.body.removeChild(downloadLink);
    }
  },
  computed: {
    url_result() {
      let result = window.location.href;
      let split_s = result.split("/");
      return split_s[6];
    },
    date_today() {
      let today = new Date();
      return today;
    }
  },
  props: {
    source: String
  }
};
</script>
<style>
body {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16px;
  font-weight: 250;
}

.u {
  border-bottom: 1px dotted #000;
  text-decoration: none;
}
u {
  border-bottom: 1px dotted #000;
  text-decoration: none;
}

* {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.table,
.th,
.td {
  border-bottom: 0.5px solid black;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-collapse: collapse;
}

.text_j {
  border-collapse: collapse;
  text-align: justify;
  text-justify: inter-word;
  line-height: 1;
}
.text_l {
  border-collapse: collapse;
  text-align: left;
  text-justify: inter-word;
  line-height: 1;
}

.text_pal {
  text-indent: 1.5em;
  border-collapse: collapse;
  text-justify: inter-word;
}

tfoot tr:hover {
  background-color: initial;
}

tbody tr:hover {
  background-color: inherit;
}

td,
th {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
}

tfoot td {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-weight: bold;
}

#verticaltext {
  writing-mode: tb-rl;
  filter: flipv fliph;
  -webkit-transform: rotate(-90deg);
  white-space: nowrap;
  display: block;
  overflow: hidden;
  padding: 0;
}

.page {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  width: 29.7cm;
  min-height: 21cm;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-top: 0cm;
  margin-right: auto;
  margin-bottom: 0cm;
  margin-left: auto;
  padding-top: 1cm;
  padding-right: 1cm;
  padding-bottom: 0.5cm;
  padding-left: 1cm;
}

.head {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 22pt;
  font-weight: bold;
  line-height: 1.5;
}

.regular14 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
}

.regular16 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
}

.bold16 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  font-weight: bold;
}

.bold14 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
  font-weight: bold;
}
.blod12 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
  font-weight: bold;
}

.blackRegula10 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 12pt;
}

.blackBold18 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 18pt;
  font-weight: bold;
}

.largerCheckbox {
  width: 30px;
  height: 30px;
  color: blue;
}
.td_line {
  border-bottom: 1pt dotted black;
}
/*.subpage {
        height: 247mm;
      }*/

.BlackBold10 {
  font-weight: bold;
}

#content {
  display: table;
}

#pageFooter {
  display: table-footer-group;
}
.text_al {
  text-align: left;
  vertical-align: top;
}
#pageFooter:after {
  counter-increment: page;
  content: counter(page);
}

.bgcolor {
  background: rgb(206, 206, 206);
}

@media print {
  .page {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    width: 29.7cm;
    min-height: 21cm;  
    margin-top: 0cm;
    margin-right: auto;
    margin-bottom: 0cm;
    margin-left: auto;
    padding-top: 1cm;
    padding-right: 1cm;
    padding-bottom: 0.5cm;
    padding-left: 1cm;   
  }
  .head {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 18pt;
    font-weight: bold;
  }

  .regular12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
  }

  .regular16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
  }

  .bold16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }

  .blod12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }

  .blackRegula10 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 14pt;
  }

  .blackBold18 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 18pt;
    font-weight: bold;
  }

  /* .head {
          font-size: 18px;
          font-weight: bold;
        }
        .regular12 {
          font-size: 12px;
        }
        .blod12 {
          font-size: 12px;
          font-weight: bold;
        }
        .blackRegula8 {
          font-size: 8px;
        } */
  .noprint {
    display: none;
  }

  .gap-10 {
    width: 100%;
    height: 10px;
  }
  .gap-20 {
    width: 100%;
    height: 20px;
  }
  .gap-30 {
    width: 100%;
    height: 30px;
  }
}
</style>
